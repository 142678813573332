import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
//import AboutProject from '../../../../../components/case-studies/AboutProject'
import ProjectShortDescription from '../../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../../components/case-studies/ColorShowcase'
import FontShowcase from '../../../../../components/case-studies/FontShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'

class Openmind extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter


    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Openmind'}
          description={'Prípadová štúdia'}
          description2={'Kompletná vizuálna identita od vizitiek až po materiály na sociálne siete pre populárne bratislavské yogové štúdio. '}
          text={'Tvorba firemnej webovej stránky, logo a branding pre štúdio OpenMind. Ak chcú firmy a značky preraziť a upevniť si svoju pozíciu na trhu, je nevyhnutné investovať do budovania brand awareness.'}
          img={data.landingImage}
          what_we_done={[
            { text: 'tvorba logotypu', link: '/nase-prace/grafika/logo-branding/openmind-branding' },
            { text: 'logomanuál' },
            { text: 'designmanuál' }
          ]}
          noShadow={true}
          className={'non-full-height mb-8'}
        />

        {/*<AboutProject
          text={'Spolupráca s Openmind prebiehala nasledovne:'}
          list={[
            {
              title: 'Ako to prebiehalo',
              text: 'Poďme sa spolu pozrieť na jednotlivé etapy vývoja projektu. Každý jeden projekt a klient je individuálny, podľa toho sa líši aj rozsah a detail prác na projekte. O momentálnej fáze tvorby je klient vždy informovaný a poskytuje nám svoj feedback.',
            },
            {
              title: 'Zadanie',
              text: 'Klient nám poskytol zadanie, ktoré sme si spolu odkonzultovali a spracovali do projektovej dokumentácie, ktorou sa následne riadil celý projekt. Niektorí klienti majú vlastnú špecifikáciu, iným ju spracujeme my na základe požiadaviek. Pri rozsiahlejších projektoch sa navrhuje celá architektúra, rozpisujú sa detailne všetky funkcie a kreslia sa use-case modely.',
            },
            {
              title: 'Riešenie',
              text: 'Od začiatku prác na projekte sa vyčlení menší tím, ktorý sa venuje práve danému projektu. Snažíme sa pracovať non-stop, aby už po krátkej dobe videl klient nejakú ukážku alebo prototyp a videli sme, či ideme správnou cestou. Ďalej projekt konzultujeme a posielame updaty, až kým sa nedopracujeme k výsledku, s ktorým sme aj my aj klient spokojný.',
            },
            {
              title: 'Výsledok',
              text: 'Na konci developmentu posielame klientovi beta verziu, kde si vie skontrolovať celý design a všetky funkcionality. Na tejto verzii rovnako testujeme aj my a optimalizujeme posledné detaily pred spustením projektu live.',
            }
          ]}
        />*/}

        <ProjectShortDescription
          className={'no-top-margin'}
          title={'Tvorba brandu'}
          description={'Predpokladom plnohodnotnej firemnej identity je pochopenie projektu a jeho zámeru. Návrh loga prichádza potom.'}
          text={'Po konzultáciách s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal projekt prezentovať. Následne sme odkomunikovali základné piliere toho, ako sa <b>odlíšiť od konkurencie</b> a cez nový branding značky ukázať jedinečnú esenciu firmy.'} />

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#ed3e7a', tintColorCode: '#f16595', nameColor: '#FFFFFF', name: 'Krikľavá ružová' },
          { code: '#fec10e', tintColorCode: '#fecd3e', nameColor: '#FFFFFF', name: 'Zlatá' },
          { code: '#6e60aa', tintColorCode: '#8b80bb', nameColor: '#FFFFFF', name: 'Ametystová' },
          { code: '#5fcae9', tintColorCode: '#7fd5ed', nameColor: '#FFFFFF', name: 'Svetlá azúrová modrá' },
        ]} />

        <FontShowcase
          fontName={'Tulpen One'}
          text={'Tulpen One je súčasné bezpätkové písmo. Nadizajnoval ho Naima Ben Ayed. Existujú štyri rezy a to: normálny, kurzíva, tučný a tučná kurzíva. Táto rodina písiem, ktorá bola špeciálne navrhnutá na nadpisy a použitie na veľkých textoch, ponúka štandardizovanú šírku znaku vo všetkých rezoch, vďaka čomu riadky textu ostávajú rovnako dlhe bez ohľadu na použitý štýl. '}
          isFullWidth={false}
          fontExampleSvg={'/images/svg/fonts/tulpen-one.svg'}
        />

        <Reveal>
          <div className={'container p-0 mt-big'}>
            <Img className={'mb-5'} alt={this.constructor.name} fluid={data.corporateIdentity.childImageSharp.fluid} />
          </div>
        </Reveal>

        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="letenky" />
          </div>
        </Reveal>

        {/*<ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'DPB'}
          nextProjectUrl={'/nase-prace/pripadove-studie/grafika/digital-print/dpb'}

        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/openmind/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/Openmind_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pageOne: file(relativePath: { eq: "case-studies/1_tatryweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwo: file(relativePath: { eq: "case-studies/4_tatryweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThree: file(relativePath: { eq: "case-studies/6_tatryweb.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/Openmind_logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    corporateIdentity: file(relativePath: { eq: "case-studies/Openmind_ci.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default Openmind
